import { css } from 'lit';
export default css`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :focus-visible {
    outline: 1px dotted var(--color-neutral-black);
  }

  // Fallback for browsers without :focus-visible support
  @supports not selector(:focus-visible) {
    :focus {
      outline: 1px dotted var(--color-neutral-black);
    }
  }
`;
