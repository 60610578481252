var KinDialog_1;
import { __decorate } from "tslib";
import { html, LitElement } from 'lit';
import { customElement, property, query, queryAssignedElements, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import { FocusTrapController } from '@vaadin/a11y-base';
import baseStyles from '../styles/base.styles.js';
import componentStyles from './kin-dialog.css.js';
/**
 * Dialog Component
 *
 * @element kin-dialog
 * @property [open=false] - Open state of dialog.
 * @property [nonDismissable=false] - Use to disable hide button, hide on escape, and hide on click outside.
 * @property [disableClickFromOutsideClose=false] - Disable closing the dialog by clicking outside only.
 * @property [size=lg] - Width of dialog.
 * @slot default - Default slot for body content.
 * @slot header - Named 'header' slot. Include a heading element (h1-h6) to ensure proper accessibility.
 * @slot footer - Named 'footer' slot. Useful for actions like cancel, confirm, etc.
 * @fires {CustomEvent<null>} kin-dialog-show Emitted when dialog is shown.
 * @fires {CustomEvent<null>} kin-dialog-hide Emitted when dialog is hidden.
 * @cssprop --kin-dialog-header-text-align - Header text align.
 * @cssprop --kin-dialog-footer-text-align - Footer text align.
 * @cssprop --kin-dialog-z-index - Dialog z-index.
 */
let KinDialog = KinDialog_1 = class KinDialog extends LitElement {
  constructor() {
    super();
    this.open = false;
    this.nonDismissable = false;
    this.disableClickFromOutsideClose = false;
    this.size = 'md';
    this.scrollBackdrop = false;
    this.footerSlotPopulated = false;
    this.returnFocusEl = null;
    this.focusTrapController = new FocusTrapController(this);
    this.mousedDownOutsidePanel = false;
    this.addController(this.focusTrapController);
  }
  /** Show the dialog */
  async show() {
    if (this.open) {
      return;
    }
    this.open = true;
  }
  /** Hide the dialog */
  async hide() {
    if (!this.open) {
      return;
    }
    this.open = false;
  }
  updated(changedProperties) {
    if (changedProperties.has('open')) {
      if (this.open === true) {
        /**
         * Store element that was focused at time of dialog open.
         * We will use to restore focus when dialog is closed.
         */
        this.returnFocusEl = document.activeElement;
        /**
         * Activate focus trap.
         *
         * Focus trap will throw an error if it can't find
         * a focusable element.
         */
        try {
          this.focusTrapController.trapFocus(this.content);
        } catch (e) {
          if (e instanceof Error) console.warn(e.message);
        }
        KinDialog_1.addBodyStyles();
        this.emit('show');
      } else if (this.open === false) {
        /**
         * Deactivate focus trap and
         * return focus to element that was used to trigger open.
         */
        this.focusTrapController.releaseFocus();
        KinDialog_1.removeBodyStyles();
        if (document.contains(this.returnFocusEl) && this.returnFocusEl instanceof HTMLElement) {
          this.returnFocusEl.focus();
        }
        this.returnFocusEl = null;
        this.emit('hide');
      }
    }
  }
  render() {
    return html`
      <div
        class=${classMap({
      dialog: true,
      'dialog--scroll-within': !this.scrollBackdrop,
      'dialog--footer-hidden': !this.footerSlotPopulated
    })}
        @mousedown=${this.handleBackdropMouseDown}
        @mouseup=${this.handleBackdropMouseUp}
        @keydown=${this.handleBackdropKeydown}
        ?open=${this.open}
      >
        <div class=${classMap(this.panelClasses)} role="dialog" aria-dialog="true" aria-labellledby="dialog-header">
          <div class="dialog__content">
            <div class="dialog__header">
              <slot name="header" id="dialog-header"></slot>
              ${this.nonDismissable ? null : html`<button class="dialog__hide" @click=${this.handleHideButtonClick} aria-label="Close dialog">
                      ${KinDialog_1.renderHideIcon()}
                    </button>`}
            </div>
            <div class="dialog__body">
              <slot></slot>
            </div>
            <div class="dialog__footer">
              <slot name="footer" @slotchange=${this.handleSlotchange}></slot>
            </div>
          </div>
        </div>
      </div>
    </div>`;
  }
  handleHideButtonClick() {
    this.hide();
  }
  /**
   * Hide dialog if both mousedown and mouseup occurred on the backdrop.
   */
  handleBackdropMouseDown(e) {
    if (this.nonDismissable || this.disableClickFromOutsideClose) {
      return;
    }
    this.mousedDownOutsidePanel = this.eventOccurredOutsidePanel(e);
  }
  handleBackdropMouseUp(e) {
    if (this.nonDismissable || this.disableClickFromOutsideClose) {
      return;
    }
    if (this.mousedDownOutsidePanel && this.eventOccurredOutsidePanel(e)) {
      this.hide();
    }
  }
  eventOccurredOutsidePanel(e) {
    if (typeof e.composedPath !== 'function') {
      return false;
    }
    return !e.composedPath().includes(this.content);
  }
  /**
   * Hide dialog on escape key.
   */
  handleBackdropKeydown(e) {
    if (this.nonDismissable) {
      return;
    }
    if (e.key !== 'Escape') {
      return;
    }
    this.hide();
  }
  static addBodyStyles() {
    document.body.style.setProperty('overflow', 'hidden');
  }
  static removeBodyStyles() {
    document.body.style.removeProperty('overflow');
  }
  get panelClasses() {
    return {
      dialog__panel: true,
      'dialog__panel--sm': this.size === 'sm',
      'dialog__panel--lg': this.size === 'lg',
      'dialog__panel--fluid': this.size === 'fluid'
    };
  }
  static renderHideIcon() {
    return html` <svg xmlns="http://www.w3.org/2000/svg" class="n-icon" viewBox="0 0 11 11">
      <path
        d="m7.216 5.625 3.712 3.712-1.59 1.591-3.713-3.712-3.712 3.712-1.591-1.59 3.712-3.713L.322 1.913 1.912.322l3.713 3.712L9.337.322l1.591 1.59-3.712 3.713z"
      />
    </svg>`;
  }
  emit(event) {
    this.dispatchEvent(new CustomEvent(`kin-dialog-${event}`, {
      bubbles: true,
      composed: true
    }));
  }
  handleSlotchange() {
    this.footerSlotPopulated = !!this.footerSlotElements.length;
  }
};
KinDialog.styles = [baseStyles, componentStyles];
__decorate([property({
  type: Boolean,
  reflect: true
})], KinDialog.prototype, "open", void 0);
__decorate([property({
  type: Boolean
})], KinDialog.prototype, "nonDismissable", void 0);
__decorate([property({
  type: Boolean
})], KinDialog.prototype, "disableClickFromOutsideClose", void 0);
__decorate([property()], KinDialog.prototype, "size", void 0);
__decorate([property({
  type: Boolean
})], KinDialog.prototype, "scrollBackdrop", void 0);
__decorate([query('.dialog__content')], KinDialog.prototype, "content", void 0);
__decorate([queryAssignedElements({
  slot: 'footer'
})], KinDialog.prototype, "footerSlotElements", void 0);
__decorate([state()], KinDialog.prototype, "footerSlotPopulated", void 0);
KinDialog = KinDialog_1 = __decorate([customElement('kin-dialog')], KinDialog);
export default KinDialog;
