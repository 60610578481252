import { signalStore, withState, withMethods, patchState, withComputed } from '@ngrx/signals';
import { StoreStatusError } from 'app/global-store/store-status.model';
import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { computed, inject } from '@angular/core';
import { SESSION_STORAGE } from '@ng-web-apis/common';
import { Policy, OtherPolicyFeature } from './policies.model';
import { createPolicy } from './policies.factory';

type StoreStatus = undefined | 'loading' | 'error' | 'success';

type StoreState<T> = {
  status: StoreStatus;
  data: T;
  error: StoreStatusError | null;
};

export type PolicyStoreState = StoreState<Policy>;

const initialState: PolicyStoreState = {
  data: createPolicy(),
  status: undefined,
  error: null,
};

const isAppliedCoverage = (coverage: OtherPolicyFeature) => coverage.value && coverage.value !== '0';
const isSinkholeExclusion = (coverage: OtherPolicyFeature) => coverage.id === 'sinkhole_exclusion' && coverage.value === true;

export const PolicyStore = signalStore(
  { providedIn: 'root' },
  withState<PolicyStoreState>(initialState),
  withComputed((store) => ({
    otherPolicyFeatures: computed(() => {
      const features = store.data().otherPolicyFeatures;
      return Array.isArray(features) ? features.filter((coverage) => isAppliedCoverage(coverage) && !isSinkholeExclusion(coverage)) : [];
    }),
    additionalCoverages: computed(() => {
      const features = store.data().otherPolicyFeatures;
      return Array.isArray(features) ? features.filter((coverage) => !isAppliedCoverage(coverage) || isSinkholeExclusion(coverage)) : [];
    }),
  })),
  withMethods((store) => ({
    updateStatus(status: StoreStatus): void {
      patchState(store, { status });
    },
    setData(data: Policy): void {
      patchState(store, { data });
    },
    setError(error: StoreStatusError | null): void {
      patchState(store, {
        status: error ? 'error' : store.status(),
        error: error || null,
      });
    },
  })),
  withStorageSync({
    key: 'policies',
    autoSync: true,
    storage: () => inject(SESSION_STORAGE),
  }),
);
