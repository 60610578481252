import { __decorate } from "tslib";
import { html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import baseStyles from '../styles/base.styles.js';
import componentStyles from './kin-alert.css.js';
import icon from './kin-alert-icons.js';
/**
 * @element kin-alert
 *
 * @slot default - The Alerts content, can be arbitrary HTML
 *
 * @property [variant="info"] Sets what kind of alert you would like. Distinct based on color and icon
 * @property [open="false"] Sets whether the alert is open or not. Alerts will not be visible without this attribute
 * @property [closable="false"] Sets whether you can close an alert or not
 * @property [duration="infinity"] Sets the time in ms before the alert is auto hidden
 */
let KinAlert = class KinAlert extends LitElement {
  constructor() {
    super(...arguments);
    this.variant = 'info';
    this.open = false;
    this.closable = false;
    this.duration = Infinity;
  }
  updated(changedProperties) {
    if (!changedProperties.has('open')) {
      return;
    }
    if (this.open === true) {
      this.autoHide();
      return;
    }
    if (this.open === false) {
      if (this.autohideTimeout) {
        window.clearTimeout(this.autohideTimeout);
      }
    }
  }
  /** Shows the Alert */
  show() {
    this.open = true;
  }
  /** Hides the Alert */
  hide() {
    this.open = false;
  }
  autoHide() {
    if (this.duration < Infinity) {
      this.autohideTimeout = window.setTimeout(() => this.hide(), this.duration);
    }
  }
  icon() {
    return icon[this.variant];
  }
  render() {
    return html`
      <div
        class="${classMap({
      alert: true,
      'alert--open': this.open,
      'alert--info': this.variant === 'info',
      'alert--warning': this.variant === 'warning',
      'alert--danger': this.variant === 'danger',
      'alert--success': this.variant === 'success'
    })}"
        role="alert"
        aria-hidden=${this.open ? 'false' : 'true'}
      >
        <span class="alert__icon">${this.icon()}</span>
        <slot class="alert__message"></slot>
        ${this.closable ? html`<button class="alert__close" @click=${this.hide} type="button">${icon.close}</button>` : ''}
      </div>
    `;
  }
};
KinAlert.styles = [baseStyles, componentStyles];
__decorate([property()], KinAlert.prototype, "variant", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
})], KinAlert.prototype, "open", void 0);
__decorate([property({
  type: Boolean,
  reflect: true
})], KinAlert.prototype, "closable", void 0);
__decorate([property({
  type: Number
})], KinAlert.prototype, "duration", void 0);
KinAlert = __decorate([customElement('kin-alert')], KinAlert);
export default KinAlert;
